import * as React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FaChevronDown } from "react-icons/fa"
import Fade from "react-reveal/Fade"

import Layout from "../components/layout"
import Seo from "../components/seo"
import VideoHero from "../components/VideoHero"
import Container from "../components/Container"
import Banner from "../components/Banner"
import SideBySide from "../components/SideBySide"
import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions"
import HDCarousel from "../components/HDCarousel"
import RoadMapHeading from "../components/RoadMapHeading"
import RoadMapSection from "../components/RoadMapSection"
import MasonryGallery from "../components/MasonryGallery"
import WhoWeAre from "../components/WhoWeAre"

export const query = graphql`
  {
    contentfulBanner {
      content {
        raw
      }
      callToActionLink {
        name
        valueUrl
        type
      }
      bannerBackground {
        title
        file {
          url
        }
      }
    }

    allContentfulTextWithImageShowcase {
      nodes {
        content {
          raw
        }
        images {
          file {
            url
          }
          title
        }
        links {
          name
          valueUrl
        }
      }
    }

    allContentfulFrequentlyAskedQuestion {
      nodes {
        question
        answerTextContent {
          raw
        }
      }
    }

    contentfulLargerImageSlider {
      images {
        file {
          url
          fileName
        }
        description
        title
      }
    }

    allContentfulRoadmapSection {
      nodes {
        content {
          raw
        }
      }
    }
    contentfulRoadmapHeading {
      title
      text {
        raw
      }
    }

    contentfulGalleryImages {
      images {
        file {
          url
        }
        title
      }
    }

    contentfulWhoAreWeComponent {
      firstTitle
      secondTitle
      paragraphText {
        paragraphText
      }
    }

    contentfulSiteConfig {
      logo {
        file {
          url
        }
      }
    }

    contentfulYoutubeHero {
      youtubeEmbedUrl
    }
  }
`

const IndexPage = ({ data }) => {
  const mapThroughSideBySides = params => {
    const {
      allContentfulTextWithImageShowcase: { nodes },
    } = data
    return nodes.map((node, idx) => <SideBySide data={node} idx={idx} />)
  }
  return (
    <Layout logo={data.contentfulSiteConfig.logo.file.url}>
      <Seo title="Home" />
      <div className="mt-3 xl:mt-3"></div>
      <div className={"p-6 xl:px-12"}>
        <Fade bottom>
          <VideoHero link={data.contentfulYoutubeHero.youtubeEmbedUrl} />
        </Fade>
      </div>
      <div className="flex justify-center mt-10">
        <div className="animate-bounce duration-1000">
          <button>
            <FaChevronDown fill="#fff" size={35} />
          </button>
        </div>
      </div>
      <Banner data={data.contentfulBanner} />
      <div>{mapThroughSideBySides()}</div>

      <section className="mt-24 xl:max-w-screen-lg mx-auto ">
        <HDCarousel data={data.contentfulLargerImageSlider.images} />
      </section>
      <section className="mt-24 roadmap-heading-section">
        <RoadMapHeading
          title={data.contentfulRoadmapHeading.title}
          content={data.contentfulRoadmapHeading.text.raw}
        />
      </section>
      <section className="roadmap-section mt-24">
        {data.allContentfulRoadmapSection.nodes.map((itm, idx) => (
          <RoadMapSection id={idx} content={itm.content.raw} />
        ))}
      </section>
      <section className="gallery-section mt-24">
        <h4 className="section-title">Half Dead Celebs</h4>
        <div className="mx-auto lg:max-w-screen-xl">
          <MasonryGallery data={data.contentfulGalleryImages.images} />
        </div>
      </section>
      <section className="who-we-are-section mt-24">
        <WhoWeAre data={data.contentfulWhoAreWeComponent} />
      </section>
      <section className="frequently-asked__section px-6 mw mt-8">
        <FrequentlyAskedQuestions
          data={data.allContentfulFrequentlyAskedQuestion.nodes}
        />
      </section>
    </Layout>
  )
}

export default IndexPage
