import React from "react"
import Faq from "react-faq-component"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { AiOutlineDown } from "react-icons/ai"

const FrequentlyAskedQuestions = ({ data }) => {
  const faqData = {
    title: "F A Q",
    rows: data.map(itm => ({
      title: itm.question,
      content: documentToReactComponents(JSON.parse(itm.answerTextContent.raw)),
    })),
  }

  const styles = {
    bgColor: "#000",
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentColor: "#fff",
    rowTitleTextSize: "24px",
    rowContentTextSize: "16px",
    rowContentPaddingBottom: "24px",
    // arrowColor: "red",
  }

  const config = {
    animate: true,
    arrowIcon: <AiOutlineDown fill="#fff" />,
    arrowColor: "orange",
    // tabFocus: true
  }
  return (
    <div className="rich-text-content rich-text-content__larger">
      <Faq data={faqData} styles={styles} config={config} />
    </div>
  )
}

export default FrequentlyAskedQuestions
