import React from "react"
import Slide from "react-reveal/Slide"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const RoadMapSection = ({ content, id }) => {
  return (
    <Slide left={id % 2 === 0} right={id % 2 === 1}>
      <div className="roadmap-box rich-content">
        {/* <p className="num">{id + 1}</p> */}
        {documentToReactComponents(JSON.parse(content))}
      </div>
    </Slide>
  )
}

export default RoadMapSection
