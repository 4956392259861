import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const RoadMapHeading = ({ title = "", content }) => {
  return (
    <div className="rich-content rich-content__roadmap">
      <h3 className="section-title">{title}</h3>
      <div className="inner-content">
        {documentToReactComponents(JSON.parse(content))}
      </div>
    </div>
  )
}

export default RoadMapHeading
