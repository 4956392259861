import React from "react"

const WhoWeAre = ({ data }) => {
  return (
    <div className="md:flex">
      <div className="titles md:w-2/6 md:pr-10">
        <h4 className="title first-title">{data.firstTitle}</h4>
        <h3 className="title second-title">{data.secondTitle}</h3>
      </div>
      <div className="rich-content md:flex-1">
        <p>{data.paragraphText.paragraphText}</p>
      </div>
    </div>
  )
}

export default WhoWeAre
