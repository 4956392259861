import React from "react"
import Fade from "react-reveal/Slide"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const SideBySide = ({ data, idx }) => {
  const { content, images, links } = data

  const renderLinks = params => {
    return links.map(({ name, valueUrl }) => (
      <a
        className="mr-3"
        href={valueUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <button className="btn btn-fill__green large xl:mt-6">{name}</button>
      </a>
    ))
  }

  return (
    <div className={`side-by-side order-${idx}`}>
      <div className="content-side rich-content">
        <Fade bottom>
          {documentToReactComponents(JSON.parse(content.raw))}
          <div className="mt-4">{renderLinks()}</div>
        </Fade>
      </div>
      <div className="image-side">
        <img src={images[0].file.url} alt={images.title || "Half Dead"} />
      </div>
    </div>
  )
}

export default SideBySide
