import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"

const HDCarousel = ({ data }) => {
  return (
    <Carousel
      infiniteLoop
      autoPlay
      interval={5000}
      showThumbs={false}
      showStatus={false}
      showArrows={false}
      centerMode={true}
      centerSlidePercentage={80}
    >
      {data.map(itm => (
        <div className="carousel-item">
          <img src={itm.file.url} alt={itm.title} />
        </div>
      ))}
    </Carousel>
  )
}

export default HDCarousel
