import React from "react"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Fade from "react-reveal/Fade"

const MasonryGallery = ({ data }) => {
  return (
    <div className="gallery-container">
      {data.map((img, idx) => (
        <Fade cascade>
          <div className="gallery-itm">
            <img className="masonry-img" src={img.file.url} alt={img.title} />
          </div>
        </Fade>
      ))}
    </div>
  )
}

export default MasonryGallery
