import React from "react"

const VideoHero = ({ link }) => {
  return (
    <div className="shadow-black shadow-lg">
      <iframe
        width="100%"
        className="iframe-video"
        src={`https://www.youtube.com/embed/${link}?controls=0`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  )
}

export default VideoHero
